<template>
	<div class="page4">
		<div class="container">
			<span class="title">Our Product</span>
			<div class="buttonbox">
				<div>Casing</div>
				<div>Crown</div>
				<div>Base moulding</div>
			</div>
			<div class="goodsbox">
				<div class="listbox">
					<div class="listitem">
						<div class="each-type" v-for="(item, index) in productsAll" :key="index">
							<template v-if="item.productId === 2 || item.productName === 'CASING'">
								<div class="each-product" v-for="(val, key2) in item.productImageList" :key="key2">
									<img :src="val.productImageUrl" />
									<span>{{ val.productImageName }}</span>
								</div>
							</template>
							<template v-if="item.productId === 3 || item.productName === 'CROWM'">
								<div class="each-product" v-for="(val, key3) in item.productImageList" :key="key3">
									<img :src="val.productImageUrl" />
									<span>{{ val.productImageName }}</span>
								</div>
								<div class="each-product">
									<img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221107/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_6147c44f377740c581bc87605260352d.png"
										@error="e => { e.target.src =  BevelImg }" alt="Bevel.png" title="Bevel.png" />
									<span>Bevel</span>
								</div>
								<div class="each-product">
									<img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221107/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_115bc0ee3c9c48d3b0b227b2153475ef.png"
										@error="e => { e.target.src =  WP4Img }" alt="WP4.png" title="WP4.png" />
									<span>WP4</span>
								</div>
							</template>
							<template v-if="item.productId === 4 || item.productName === 'BASE.MOULDING'">
								<div class="each-product" v-for="(val, key4) in item.productImageList" :key="key4">
									<img :src="val.productImageUrl" />
									<span>{{ val.productImageName }}</span>
								</div>
							</template>
						</div>
					</div>
				</div>
				<button class="more">MORE+</button>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from '@/axios'
	export default {
		name: 'Page4',
		components: {},
		data() {
			return {
				productsAll: [],
				products: [],
				BevelImg: require('@/assets/image/Bevel.png'),
				WP4Img: require('@/assets/image/WP4.png')
			}
		},
		async created() {
			const res = await axios.post('/web/PProduct/findPProductNameImageByIds?productNameIds=2,3,4')
			this.productsAll = res.data || []
			// for (let i = 0; i < this.productsAll.length; i++) {
			//   let elem = this.productsAll[i]
			//   if (elem.productId === 2) {
			//     this.products = elem.productImageList || []
			//   }
			// }
		},
		methods: {
			showProduct(index) {
				return
				let _res =
					this.productsAll.filter((item) => {
						return item.productId === index
					})[0] || {}
				this.products = _res.productImageList || []
			}
		}
	}
</script>
<style scoped lang='scss'>
	.page4 {
		background-color: #ffffff;

		.title {
			display: block;
			text-align: center;
			font-size: 66px;
			margin-top: 170px;
			margin-bottom: 45px;
			font-weight: 500;
		}

		.buttonbox {
			width: 1495px;
			margin: auto;
			background-color: #73bc2e;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 40px;

			div {
				width: 308px;
				height: 56px;
				margin: 45px 0;
				text-align: center;
				line-height: 56px;
				color: #ffffff;
				font-size: 38px;
				border-right: 2px solid #ffffff;
				cursor: pointer;
			}

			div:last-child {
				border-right: none;
			}
		}

		.goodsbox {
			width: 1495px;
			margin: 30px auto;

			.title {
				font-size: 66px;
				font-weight: 500;
				color: #3b3b3b;
				line-height: 80px;
				margin-top: 40px;
				padding-left: 15px;
			}

			.ltitle {
				font-size: 42px;
				font-weight: 500;
				color: #838383;
				line-height: 80px;
				margin-top: 20px;
				margin-bottom: 40px;
				padding-left: 15px;
			}

			.listbox {
				// display: flex;
				// flex-direction: row;
				// flex-wrap: wrap;
				// align-items: center;
				padding-bottom: 20px;

				// justify-content: center;
				.listitem {
					// width: 25%;
					display: flex;
					/* // flex-direction: column;
					// align-items: center;
					// justify-content: flex-start; */
					justify-content: space-between;
					flex-wrap: wrap;
					font-size: 30px;

					.each-product {
						width: 23%;
						/* // display: flex;
						// flex-direction: column; */
					}

					.each-type {
						display: contents;
					}

					img {
						width: 100%;
						height: 200px;
						display: block;
						margin-bottom: 20px;
					}

					span {
						width: 100%;
						text-align: center;
						display: block;
						min-height: 80px;
					}
				}
			}
		}

		.more {
			width: 280px;
			height: 90px;
			border: none;
			background: #73bc2e;
			border-radius: 6px;
			font-size: 40px;
			color: #ffffff;
			font-weight: 500;
			margin: auto;
			display: block;
			margin-top: 100px;
		}
	}
</style>
