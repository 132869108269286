<template>
  <div class="header">
    <div class="container">
      <router-link to="/">
        <img class="logo" alt="Vue logo" src="../assets/logo.png" />
      </router-link>
      <div class="links">
        <router-link pointer-events="none" class="link" to="/">HOME</router-link>
        <div class="link shows">
          <router-link class="linktitle" aria-disabled to="/products">PRODUCTS</router-link>
          <div class="linkitem">
            <div class="linkitem_div" v-for="(item, key) in list" :key="key">
              <div class="title">{{ item.productTypeName }}</div>
              <router-link v-for="(row, index) in item.pproductList" :key="index" :to="type + row.id">{{ row.productName }}</router-link>
            </div>
          </div>
          <!-- <div class="linkitem">
            <div class="title">Moulding</div>
            <router-link to="/products?type=CASING">CASING</router-link>
            <router-link to="/products?type=CROWM">CROWM</router-link>
            <router-link to="/products?type=BASE.MOULDING"
              >BASE.MOULDING</router-link
            >
            <router-link to="/products?type=S4S">S4S</router-link>
            <router-link to="/products?type=BEVELSIDING"
              >BEVEL SIDING</router-link
            >
            <router-link to="/products?type=T&G">T&G</router-link>
            <router-link to="/products?type=JAMB">JAMB</router-link>
            <router-link to="/products?type=OTHERMOULDING"
              >OTHER MOULDING</router-link
            >
            <div class="title">Shutters</div>
            <router-link to="/products?type=WoodShutters"
              >Wood Shutters</router-link
            >
          </div> -->
        </div>
        <router-link class="link" to="/manufacturing">MANUFACTURING</router-link>
        <router-link class="link" to="/about">ABOUT US</router-link>
        <router-link class="link" to="/contact">CONTACT US</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
export default {
  name: 'Header',
  props: {
    color: String
  },
  data() {
    return {
      list: [],
      type: '/products?type='
    }
  },
  async created() {
    const res = await axios.post('/web/PProduct/findPProductTypeAndName')
    const list = res.data
    this.list = list
  }
}
</script>
<style scoped lang="scss">
.header {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  height: 120px;
  z-index: 3000;
  .container {
    margin: auto;
    width: 1400px;
    height: 120px;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .logo {
      width: 450px;
      // width: 117px;
      height: 107px;
      position: relative;
      img {
        height: 100%;
        width: 100%;
      }
    }
    .links {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .link {
        cursor: pointer;
        display: block;
        padding: 20px 10px;
        text-decoration: none;
        font-weight: 400;
        font-size: 18px;
        position: relative;
        font-family: DINNextLTPro-Regular;
        color: #1e1e1e;
        // word-break: keep-all;
        .linktitle {
          text-decoration: none;
          color: #333333;
        }
        .router-link-active {
          color: #72bd2d;
        }
        .linkitem {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 62px;
          padding: 20px 0;
          width: 260px;
          background-color: #ffffff;
          box-shadow: 1px 2px 2px 0 #f1f1f1;
          .linkitem_div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          a {
            text-decoration: none;
            padding: 10px 50px;
            color: #666666;
          }
          .title {
            font-size: 18px;
            padding: 20px 50px;
            font-weight: bold;
          }
          .router-link-exact-active {
            color: #72bd2d;
          }
        }
      }
      .router-link-exact-active {
        color: #72bd2d;
      }
      .shows {
        .linkitem {
          display: none;
        }
      }
      .shows:hover .linkitem {
        display: block;
      }
    }
  }
}
</style>
