// import { getRpSign } from "@/commons";
import Axios from "axios";
import Qs from "qs";
import { config, reqJson as reqConfigJson } from "./config";
export const baseURL = '';
// export const baseURL = process.env.VUE_APP_API;

const axios = Axios.create({
  baseURL,
  timeout: 60000,
  validateStatus: () => true, // 无论如何都返回成功
  // transformRequest: [
  //   (data = {}) => (data instanceof FormData ? data : Qs.stringify(data))
  // ],
  headers: {
    "Content-Type": "application/json;charset=UTF-8"
  }
});
export default config(axios, {
  msgKey: "message", // 提醒msg的字段 为空则不会展示提醒
  // codeKey: "code", // 返回校验的key 为空则恒为正确
  codeKey: "code", // 返回校验的key 为空则恒为正确
  // codeValue: "1", // 返回校验的成功值,
  codeValue: 200, // 返回校验的成功值,
  useToken: true,
  responseType: "json",
  title: "正在请求中..." // 加载中文本 为空不显示加载
});
/**
 *
 * @method getServiceURL
 * @param  path {string}  路径参数
 * @param data {any} 路径query传递的参数
 * @param useToken {boolean} 是否添加token
 * @param useRpSign {boolean} 是否传递时间戳
 */

export const reqJson = reqConfigJson,
  getServiceURL = (
    path = "",
    data = {},
  ) => {
    const { href: hostPath } = new URL(
      process.env.VUE_APP_API,
      window.location
    ),
      { href: basePath } = new URL(baseURL, hostPath),
      url = new URL(path, basePath);
    // 添加请求参数
    if (!!data) {
      for (const key in data)
        if (data.hasOwnProperty(key)) url.searchParams.append(key, data[key]);
    }
    return url.href;
  };
