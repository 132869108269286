<template>
	<div class="page2">
		<div class="container container1">
			<div style="display: flex;">
				<img class="page2-img1" src="https://img.lingmikeji.com/40c96d019bdec58a3c2c955a0caa9a6.png"
					@error="e => { e.target.src =  home01Img }" alt="home01.png" title="home01.png" />
				<div class="page2-pdiv">
					<p class="page2-p">Trim boards, Battens, Bevel siding,
						S4S, wall panels, ceiling Etc</p>
					<p class="page2-p">Primed Wood mouldings, Base
						mouldings, Frames, Crown mouldings ,Casing Etc</p>
					<p class="page2-p">Shutter components: Louver, stile,
						rail, Frames, tilt Rod</p>
					<p class="page2-p">Timber and Poly Finished shutters: Square shutters,
						shaped shutters, stained shutters</p>
				</div>
			</div>
			<div class="page2-bottom">
				<img class="page2-img2" src="https://img.lingmikeji.com/03fd95d2a8326cb6d3ec8a8389abf2c.png"
					@error="e => { e.target.src =  home02Img }" alt="home02.png" title="home02.png" />
				<img class="page2-img3" src="https://img.lingmikeji.com/4e0cd7e5554fc86c12cc2b582ec6aa3.png"
					@error="e => { e.target.src =  home03Img }" alt="home03.png" title="home03.png" />
				<div class="page2-imgdiv">
					<img class="page2-img4" src="https://img.lingmikeji.com/eb8bdfa6734f43c9fea124c8a77a44a.png"
						@error="e => { e.target.src =  home04Img }" alt="home04.png" title="home04.png" />
					<img class="page2-img5" src="https://img.lingmikeji.com/b1c356e9e56e1b423cbc2174705bb69.png"
						@error="e => { e.target.src =  home05Img }" alt="home05.png" title="home05.png" />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'page2',
		data() {
			return {
				home02Img: require('@/assets/image/home02.png'),
				home2Img: require('@/assets/image/home2.png'),
				home04Img: require('@/assets/image/home04.png')
			}
		}
	}
</script>
<style scoped lang='scss'>
	.page2 {
		// height: 1690px;
		height: 1282px;
		background-color: #ffffff;

		.container {
			width: 1495px;
			margin: auto;
			position: relative;

			.leftbox {
				width: 800px;
				position: relative;

				img:nth-child(1) {
					width: 719px;
					height: 539px;
					position: relative;
					margin-left: 60px;
					z-index: 3;
				}

				.lefttxt {
					width: 670px;
					height: 379px;
					font-family: DINNextLTPro-Regular;
					line-height: 48px;
					color: #344948;
					margin-top: 40px;
					font-size: 28px;
					overflow: hidden;

					p {
						position: relative;
						padding-left: 20px;

						&::before {
							content: '.';
							position: absolute;
							left: 0;
							top: 0px;
						}
					}
				}
			}

			.rightbox {
				display: flex;
				flex-direction: column;
				// align-items: flex-end;
				justify-content: center;
				position: relative;
				margin-left: 90px;

				.line {
					width: 580px;
					height: 0;
					border-bottom: 3px solid #72bd2d;
					position: relative;
					padding-top: 100px;
					margin-left: 40px;
				}

				.img1 {
					width: 697px;
					height: 465px;
					position: relative;
					margin-top: 80px;
					margin-left: -40px;
					z-index: 2;
				}

				.img2 {
					width: 726px;
					height: 442px;
					position: relative;
					margin-top: 43px;
					margin-left: -180px;
					z-index: 1;
				}

				.text {
					width: 80%;
					font-size: 32px;
					line-height: 50px;
				}
			}

			.imgbox {
				background-color: #f7f7f7;
				width: 420px;
				margin: 24px;

				img {
					width: 100%;
				}
			}
		}

		.container:nth-child(1) {
			padding-top: 64px;
			padding-bottom: 60px;
		}

		.container:nth-child(2) {
			width: 100%;
			min-width: 100%;
		}
	}

	.page2-img1 {
		width: 535px;
		height: 452px;
	}

	.page2-pdiv {
		margin-left: 57px;
		width: 737px;
	}

	.page2-p {
		font-size: 28px;
		color: #344948;
		margin-bottom: 80px;
		font-family: DINNextLTPro-Regular;
	}

	.page2-p:last-child {
		margin-bottom: 0px;
	}

	.page2-bottom {
		display: flex;
		height: 560px;
		margin-top: 48px;
	}

	.page2-img2 {
		width: 535px;
		height: 560px;
	}

	.page2-img3 {
		width: 594px;
		height: 560px;
		margin-left: 37px;
	}

	.page2-imgdiv {
		margin-left: 20px;
	}

	.page2-img4 {
		width: 310px;
		height: 258px;
	}

	.page2-img5 {
		width: 310px;
		height: 275px;
		margin-top: 23px;
	}
</style>
