<template>
  <div class="manufacturing">
    <div class="banner">
      <img class="img" src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_7a222e5609644490aaf91e28bad47365.png" @error="e => { e.target.src =  manufacturingImg }" alt="manufacturing.png" title="manufacturing.png" />
      <div class="back">
        <span class="title">BE THE BEST SUPPLIER</span>
      </div>
    </div>
    <div class="painting" style="margin-bottom:80px">
      <div class="container">
        <div class="titlebox">
          <span class="titleback">Production line</span>
          <span class="title">Production line</span>
        </div>
        <div class="flexbox">
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_0e8ce483120344dda0a4e8e0b3be6c23.png" @error="e => { e.target.src =  mfg12Img }" alt="mfg12.png" title="mfg12.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_93f7ff920deb40c1b2f826bc28ad1bf2.png" @error="e => { e.target.src =  mfg17Img }" alt="mfg17.png" title="mfg17.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_0d324319245f434ca2e929e0a2385a55.png" @error="e => { e.target.src =  mfg13Img }" alt="mfg13.png" title="mfg13.png" />
          <div class="line line-3">Moulding</div>
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_57662d7e36cf4c40ba344540100142c9.png" @error="e => { e.target.src =  mfg1Img }" alt="mfg1.png" title="mfg1.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_35564f268ae246ce88bc732b53e934c4.png" @error="e => { e.target.src =  mfg2Img }" alt="mfg2.png" title="mfg2.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_f05ef89f222f4b60aaaf3d5619d905e6.png" @error="e => { e.target.src =  mfg3Img }" alt="mfg3.png" title="mfg3.png" />
          <div class="line"><label class="line-2">Roller Coating - 1ST PRIMER</label><label class="line-1">Sanding after 1st Primer</label></div>
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_96738e822c6e44819d1afae7ddb2c30f.png" @error="e => { e.target.src =  mfg4Img }" alt="mfg4.png" title="mfg4.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_8baf8406facf4e7babd4eb8e7a28c120.png" @error="e => { e.target.src =  mfg5Img }" alt="mfg5.png" title="mfg5.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_44f6a42767e54694ab78af60bb230a7c.png" @error="e => { e.target.src =  mfg6Img }" alt="mfg6.png" title="mfg6.png" />
          <div class="line line-3">Spray painting as 2nd Coat</div>
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_87cd7c4e4e6645aa921daddc0e766de6.png" @error="e => { e.target.src =  mfg7Img }" alt="mfg7.png" title="mfg7.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_682fb0cae2d147d3a1b71bf1b3ab7902.png" @error="e => { e.target.src =  mfg8Img }" alt="mfg8.png" title="mfg8.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_33557c87e3254612888454e2b0ccb6b3.png" @error="e => { e.target.src =  mfg14Img }" alt="mfg14.png" title="mfg14.png" />
          <div class="line">
            <label class="line-11">Go through drying machine after spray paint</label>
            <label class="line-11">Inspection</label>
            <label class="line-11">Packing</label>
          </div>
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_7e872eb903604508a306b89a93f57aab.png" @error="e => { e.target.src =  mfg9Img }" alt="mfg9.png" title="mfg9.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_8f6a7cf28efc49a592dd953c7b88b74b.png" @error="e => { e.target.src =  mfg10Img }" alt="mfg10.png" title="mfg10.png" />
          <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_ade0bc1b3fdf4ed7a591c064d77ab109.png" @error="e => { e.target.src =  mfg11Img }" alt="mfg11.png" title="mfg11.png" />
          <div class="line"><label class="line-1-n">Packing</label><label class="line-2-p">Ready loading</label></div>
        </div>
      </div>
      <div class="radius-cirle cirl-l"></div>
      <div class="radius-cirle cirl-r"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'manufacturing',
  data() {
    return {
      manufacturingImg: require('@/assets/image/manufacturing.png'),
      mfg12Img: require('@/assets/image/mfg12.png'),
      mfg17Img: require('@/assets/image/mfg17.png'),
      mfg13Img: require('@/assets/image/mfg13.png'),
      mfg1Img: require('@/assets/image/mfg1.png'),
      mfg2Img: require('@/assets/image/mfg2.png'),
      mfg3Img: require('@/assets/image/mfg3.png'),
      mfg4Img: require('@/assets/image/mfg4.png'),
      mfg5Img: require('@/assets/image/mfg5.png'),
      mfg6Img: require('@/assets/image/mfg6.png'),
      mfg7Img: require('@/assets/image/mfg7.png'),
      mfg8Img: require('@/assets/image/mfg8.png'),
      mfg14Img: require('@/assets/image/mfg14.png'),
      mfg9Img: require('@/assets/image/mfg9.png'),
      mfg10Img: require('@/assets/image/mfg10.png'),
      mfg11Img: require('@/assets/image/mfg11.png')
    }
  }
}
</script>
<style scoped lang='scss'>
.manufacturing {
  .banner {
    height: 530px;
    position: relative;
    .img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
    }
    .back {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      position: relative;
      z-index: 1;
      .title {
        font-size: 66px;
      }
      .ltitle {
        font-size: 46px;
        margin-top: 30px;
      }
    }
  }
  .painting {
    position: relative;
    .radius-cirle {
      position: absolute;
      z-index: -1;
      width: 300px;
      height: 600px;
      border: 97px solid #d9ffea;
      &.cirl-r {
        top: 200px;
        right: 0;
        border-radius: 600px 0 0 600px;
        border-right: 0;
      }
      &.cirl-l {
        // top: 1000px;
        bottom: 100px;
        left: 0;
        border-radius: 0 600px 600px 0;
        border-left: 0;
      }
    }
    .ring {
      height: 500px;
      width: 500px;
      border-radius: 400px;
      border: 96px solid #d9ffea;
      position: absolute;
    }
    .ring1 {
      right: -280px;
      top: 400px;
    }
    .ring2 {
      left: -280px;
      bottom: 0px;
    }
    .container {
      position: relative;
      z-index: 1;
      max-width: 1350px;
      width: 80%;
      min-width: 1100px;
      margin: auto;
      .titlebox {
        padding-left: 10px;
        height: 100px;
        margin-top: 140px;
        position: relative;
        .title {
          font-weight: 500;
          position: relative;
          z-index: 1;
          font-size: 66px;
          font-family: PingFangSC-Medium;
          color: #121217;
        }
        .titleback {
          position: absolute;
          font-size: 80px;
          color: #e2eff6;
          z-index: 0;
          top: -20px;
        }
      }
      .flexbox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: auto;
        margin-bottom: 400px;
        img {
          width: 31%;
          margin: 10px 1%;
        }
        span {
          margin: 10px 1%;
          display: block;
          width: 31%;
          background-color: #73bc2e;
          color: #ffffff;
          font-size: 32px;
          text-align: center;
          line-height: 52px;
          font-weight: 500;
          padding: 10px 0;
        }
        .line {
          width: 100%;
          line-height: 74px;
          font-size: 30px;
          font-family: PingFangSC-Medium;
          color: #ffffff;
          margin: 23px 2% 87px 1%;
          display: flex;
          justify-content: space-between;
          text-align: center;
          &.line-3 {
            width: 100%;
            background-color: rgba(104, 167, 45, 1);
            justify-content: center;
          }
          .line-2 {
            width: 74%;
            background-color: rgba(104, 167, 45, 1);
            // padding-right: 2%;
            margin-right: 2%;
          }
          .line-1 {
            width: 36%;
            background-color: rgba(104, 167, 45, 1);
            // margin-left: 2%;
          }
          .line-11 {
            width: 32%;
            background-color: rgba(104, 167, 45, 1);
            line-height: 74px;
            height: 74px;
            &:first-child {
              height: 148px;
              line-height: 74px;
            }
          }
          .line-22 {
            width: 66%;
            background-color: rgba(104, 167, 45, 1);
          }
          .line-2-p {
            width: 66%;
            background-color: rgba(104, 167, 45, 1);
            line-height: 74px;
            height: 74px;
          }
          .line-1-n {
            width: 32%;
            background-color: rgba(104, 167, 45, 1);
            line-height: 74px;
            height: 74px;
          }
        }
      }
    }
  }
}
</style>