<template>
  <div class="home">
    <Page1 />
    <Page2 />
    <Page3 />
    <Page4 />
    <!-- <Page5 /> -->
    <Page6 />
  </div>
</template>

<script>
import Page1 from "./Home/Page1.vue";
import Page2 from "./Home/Page2.vue";
import Page3 from "./Home/Page3.vue";
import Page4 from "./Home/Page4.vue";
// import Page5 from "./Home/Page5.vue";
import Page6 from "./Home/Page6.vue";
export default {
  name: "Home",
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    // Page5,
    Page6,
  },
};
</script>
<style scoped lang='scss'>
.home {
}
</style>