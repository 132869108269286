<template>
	<div class="page3">
		<div class="container">
			<div class="each_div">
				<img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_875dc893e7d846beb4da5468b4b2a281.png"
					@error="e => { e.target.src =  home05Img }" alt="home05.png" title="home05.png" />
				<div class="textbox">
					<span>A wide range of moulding styles for crown,baseboard,wainscotting,and
						MDF moulding,interior-doors and trim casing are available,glad to
						offer special shape products to accessorize you home.</span>
				</div>
			</div>
			<div class="each_div">
				<img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_eb51f83d8c84404b8a55cfb17811244d.png"
					@error="e => { e.target.src =  home8Img }" alt="home8.png" title="home8.png" />
				<div class="textbox">
					<span>To make your home with the luxury and elegance a real wood mouldings is a good choice. Our
						wood mouldings are available in Paulownia, poplar and pine, with competitive price with high
						quality our paulownia mouldings have been market leading in the world.</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'page3',
		data() {
			return {
				home05Img: require('@/assets/image/home05.png'),
				home8Img: require('@/assets/image/home8.png')
			}
		}
	}
</script>
<style scoped lang='scss'>
	.page3 {
		height: 1120px;
		/* background-color: #e6eeec; */

		.container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.each_div {
				width: 1495px;
				margin: auto;
				display: flex;
				align-items: center;

				&:hover {
					.textbox {
						background-color: #caf1e7;
					}
				}

				img {
					width: 50%;
					height: 574px;
					position: relative;
				}

				.textbox {
					width: 50%;
					height: 574px;
					display: inline-flex;
					flex-direction: column;
					justify-content: center;
					background-color: #f6f6f6;
					font-weight: 100;
					font-family: DINNextLTPro-Regular;
					color: #8d8d8d;
					font-size: 28px;

					span {
						display: block;
						margin: 0px 60px;
						line-height: 40px;
					}
				}
			}
		}
	}
</style>
