<template>
  <div class="page6">
    <div class="container">
      <span class="title">Fill in the information</span>
      <div class="box">
        <div class="item">
          <div class="label">Name</div>
          <div class="inputbox">
            <span>*</span>
            <input v-model="name" />
          </div>
        </div>
        <div class="item">
          <div class="label">Country</div>
          <div class="inputbox">
            <span>*</span>
            <input v-model="country" />
          </div>
        </div>
      </div>
      <div class="box">
        <div class="item">
          <div class="label">Email</div>
          <div class="inputbox">
            <span>*</span>
            <input v-model="email" />
          </div>
        </div>
        <div class="item">
          <div class="label">Mobile phone</div>
          <div class="inputbox">
            <span></span>
            <input v-model="phone" />
          </div>
        </div>
      </div>
      <div class="box">
        <div class="item">
          <div class="label">Message</div>
          <div class="inputbox in-textarea">
            <span>*</span>
            <input v-model="message" />
          </div>
        </div>
      </div>
      <button class="submit" @click="sendEmail">SUBMIT</button>
    </div>
  </div>
</template>

<script>
import axios from '@/axios'
import { Message } from 'element-ui'
export default {
  name: 'Page6',
  components: {},
  data() {
    return {
      name: '',
      email: '',
      country: '',
      phone: '',
      message: ''
    }
  },
  methods: {
    async sendEmail() {
      await axios.post('/web/email/sendEmail', {
        email: this.email,
        message: this.country + ' ' + this.message,
        name: this.name,
        phone: this.phone
      })
      Message.success('提交成功')
    }
  }
}
</script>
<style scoped lang='scss'>
.page6 {
  // height: 1010px;
  height: 994px;
  background-color: #ffffff;
  padding-top: 30px;
  .title {
    font-size: 52px;
    font-weight: bold;
    line-height: 73px;
    text-align: center;
    display: block;
  }
  .box {
    display: flex;
    align-items: center;
    width: 1200px;
    margin: auto;
    .item {
      flex: 1;
      .label {
        font-size: 28px;
        margin: 25px 20px;
      }
      .inputbox {
        height: 76px;
        background: #f2f9eb;
        border-radius: 4px;
        margin: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #ff1313;
          font-size: 24px;
          display: block;
          width: 50px;
          text-align: center;
          padding-top: 8px;
        }
        input {
          line-height: 76px;
          border: none;
          flex: 1;
          background: none;
          font-size: 24px;
        }
        &.in-textarea {
          height: 278px;
        }
      }
    }
  }
  .submit {
    border-radius: 5px;
    border: none;
    color: #ffffff;
    background-color: #72bd2d;
    padding: 20px 100px;
    font-size: 24px;
    display: block;
    margin: 20px auto;
  }
}
</style>