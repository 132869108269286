// import { getRpSign } from "@/commons/index";
import { Message, Loading } from "element-ui";
// import getMockData from "./mock";
let loadingNumber = 0,
  loadingInstance = null;
const defaultLoadOption = {
  text: "加载中...",
  background: "rgba(255,255,255,0)"
},
  mockReg = /mock:/i, // 识别mock接口的正则
  // 当mock时 处理流程
  adapter = config => {
    const { url } = config;
    const key = url.replace(mockReg, "");
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          config: config,
          // data: getMockData(key),
          status: 200,
          statusText: ""
        });
      }, 800);
    });
  },
  openLoad = option => {
    loadingNumber++;
    loadingInstance = Loading.service({ ...defaultLoadOption, ...option });
  },
  closeLoad = (clearAll = false) => {
    loadingNumber = Math.max(clearAll ? 0 : loadingNumber - 1, 0);
    if (loadingNumber === 0 && loadingInstance) {
      loadingInstance.close();
      loadingInstance = null;
    }
  };
export const config = (axios, defaultOpt = {}) => {
  const status2message = status => {
    let message = "未知错误";
    switch (status) {
      case 400:
        message = "请求错误(400)";
        break;
      case 401:
        message = "未授权，请重新登录(401)";
        break;
      case 403:
        message = "拒绝访问(403)";
        break;
      case 404:
        message = "请求出错(404)";
        break;
      case 408:
        message = "请求超时(408)";
        break;
      case 500:
        message = "服务器错误(500)";
        break;
      case 501:
        message = "服务未实现(501)";
        break;
      case 502:
        message = "网络错误(502)";
        break;
      case 503:
        message = "服务不可用(503)";
        break;
      case 504:
        message = "网络超时(504)";
        break;
      case 505:
        message = "HTTP版本不受支持(505)";
        break;
      default:
        return `服务出错(${status})!`;
    }
    return `${message}，请检查网络或联系管理员！`;
  },
    setRequest = config => {
      const params = config.params || {},
        currentConfig = {
          ...defaultOpt,
          ...config,
          params
        };
      // 添加时间戳 和签名
      // const rpSign = getRpSign();
      // for (const key in rpSign) {
      //   if (rpSign.hasOwnProperty(key)) {
      //     config.headers[key] = rpSign[key];
      //   }
      // }
      // 需要token
      // if (currentConfig.useToken)
      //   config.headers.token = require("@/store/index").default.state.userInfo.token;
      // 需要loading展示
      if (!!currentConfig.title) {
        openLoad({
          text: currentConfig.title
        });
        // loadingInstance = Loading.service();
      }
      if (currentConfig.responseType !== "json") currentConfig.timeout = 0;
      // 走mock
      // if (mockReg.test(currentConfig.url)) {
      //   currentConfig.adapter = adapter;
      // }
      return currentConfig;
    },
    setResponse = response => {
      const {
        data,
        status,
        config: { responseType, codeValue, msgKey, codeKey, title }
      } = response;
      if (!!title) closeLoad();
      if (!!codeKey) {
        if (responseType === "json") {
          if (data[codeKey] === codeValue) {
            // 完全符合
          } else {
            // 异常返回
            if (!!msgKey) {
              const message = data[msgKey] || status2message(status);
              Message.closeAll();
              Message({
                message,
                type: "error",
                showClose: true
              });
              switch (status) {
                case 401:
                  // require("@/router/index").default.replace({
                  //   name: "login"
                  // });
                  // require("@/store/index").default.dispatch(
                  //   "userInfo/logout"
                  // );
                  break;

                default:
                  break;
              }
            }
            return Promise.reject(data);
          }
        } else {
          // 非JSON
          if (status !== 200) return Promise.reject(data);
        }
      } else {
        // 无需验证返回
      }
      return Promise.resolve(data);
    };
  axios.interceptors.request.use(setRequest); //发起请求
  axios.interceptors.response.use(setResponse, ({ config: { title } }) => {
    Message.closeAll();
    Message({
      message: "链接超时",
      type: "error",
      showClose: true
    });
    if (title) closeLoad();
    return Promise.reject(response);
  }); // 获取信息
  return axios;
},
  reqJson = {
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    },
    transformRequest: data => {
      return typeof data === "string" ? data : JSON.stringify(data);
    }
  };
