import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Manufacturing from '../views/Manufacturing.vue'
import Contact from '../views/Contact.vue'
import Products from '../views/Products.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Home' },
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'About' },
  },
  {
    path: '/manufacturing',
    name: 'Manufacturing',
    component: Manufacturing,
    meta: { title: 'Manufacturing' },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact' },
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    meta: { title: 'Products' },
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    document.title = 'HENGDA-WOOD - ' + to.meta.title
  }
  next()
})
export default router
