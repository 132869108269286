<template>
  <div class="contact">
    <div class="banner">
      <img class="img" src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_586aee9d2099491bb1130b4ddc080c1f.png" @error="e => { e.target.src =  aboutUsBannerImg }" alt="aboutus-banner.png" title="aboutus-banner.png" />
      <div class="back">
        <span class="title">HIGH QUALITY,GOOD REPUTATION</span>
      </div>
    </div>
    <div class="formbox">
      <div class="left">
        <span class="title">CONTACT US</span>
        <div class="inputbox">
          <span>*</span>
          <input placeholder="Name" v-model="name" />
        </div>
        <div class="inputbox">
          <span>*</span>
          <input placeholder="Email" v-model="email" />
        </div>
        <div class="inputbox">
          <input placeholder="Mobile phone" v-model="phone" />
        </div>
        <div class="inputbox" style="height: 186px">
          <span>*</span>
          <input placeholder="Message" v-model="message" />
        </div>
        <button @click="sendEmail" class="submit">SUBMIT</button>
      </div>
      <div class="right">
        <span class="title">Location Details</span>
        <div class="item">
          <span>Phone</span>
          <span>+86 530-616-0072</span>
          <span>+86 530-616-0078</span>
        </div>
        <div class="item">
          <span>Email</span>
          <span>hengdawood@vip.163.com</span>
          <span>sales@hengdawood.com</span>
        </div>
        <div class="item">
          <span>Address</span>
          <span>Zhuangzhai Town,Cao County,Heze City,Shandong Province,China</span>
        </div>
      </div>
    </div>
    <div class="mapbox">
      <el-amap class="amap-box" :zoom="zoom" :center="center" :mapStyle="mapStyle">
        <el-amap-marker :position="[115.227756, 35.031992]" :icon="icon"></el-amap-marker>
      </el-amap>
    </div>
  </div>
</template>
<script>
import axios from '@/axios'
import { Message } from 'element-ui'
export default {
  name: 'Contact',
  components: {},
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      message: '',
      center: [115.227756, 35.031992], //地图中心点坐标
      zoom: 16, //初始化地图显示层级
      mapStyle: 'amap://styles/8b6be8ec497009e17a708205348b899a', //设置地图样式
      icon: '',
      aboutUsBannerImg: require('@/assets/image/aboutus-banner.png')
    }
  },
  methods: {
    async sendEmail() {
      await axios.post('/web/email/sendEmail', {
        email: this.email,
        message: this.message,
        name: this.name,
        phone: this.phone
      })
      Message.success('提交成功')
    }
  }
}
</script>
<style scoped lang='scss'>
.contact {
  .banner {
    height: 530px;
    position: relative;
    .img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
    }
    .back {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      position: relative;
      z-index: 1;
      // background-color: rgba(0, 0, 0, 0.5);
      .title {
        font-size: 66px;
      }
      .ltitle {
        font-size: 46px;
        margin-top: 30px;
      }
    }
  }
  .formbox {
    width: 1200px;
    margin: auto;
    display: flex;
    padding: 80px 0;
    .left {
      width: 660px;
      .title {
        font-size: 50px;
        font-weight: bold;
        display: block;
        margin-bottom: 60px;
      }
      .inputbox {
        border: 1px solid #d1d1d1;
        border-radius: 5px;
        display: flex;
        padding: 0 20px;
        align-items: center;
        margin-bottom: 46px;
        justify-content: center;
        height: 60px;
        span {
          color: #fe1209;
          padding-right: 20px;
          line-height: 0;
        }
        input {
          border: none;
          flex: 1;
          ::placeholder {
            color: #d1d1d1;
            font-size: 24px;
          }
        }
      }
      .submit {
        border-radius: 5px;
        border: none;
        color: #ffffff;
        background-color: #72bd2d;
        padding: 20px 100px;
        font-size: 24px;
      }
    }
    .right {
      background-color: #2a2a2a;
      color: #ffffff;
      flex: 1;
      margin-left: 40px;
      height: 540px;
      padding: 50px;
      .title {
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        display: block;
        margin-bottom: 30px;
        line-height: 40px;
      }
      .item {
        display: flex;
        flex-direction: column;
        margin: 10px 0;
        span {
          font-size: 28px;
          opacity: 0.8;
          display: block;
        }
        span:nth-child(1) {
          font-size: 30px;
          font-weight: 500;
          margin-bottom: 10px;
          margin-top: 10px;
          opacity: 1;
        }
      }
    }
  }
  .mapbox {
    height: 540px;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
</style>
