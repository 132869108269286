<template>
  <div class="page1">
    <div class="container">
      <div class="leftbox">
        <div class="line"></div>
        <span class="title">GOOG MOULDING</span>
        <span class="title">FOR BEAUTIFUL HOMES</span>
        <span class="tip">Hengda wood's main products are baseboard, wall panels, S3S and S4S boards,
          door and window frame or casing,handrails, crown mouldings ,shutter components ect.
        </span>
        <button class="button">SHOP NOW</button>
      </div>
      <div class="rightbox"><img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_84564bf63ffd4d7c9ed2a92076be2b4b.png" @error="e => { e.target.src =  home_headerImg }" alt="home_header.png" title="home_header.png" /></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'page1',
  data() {
    return {
      home_headerImg: require('@/assets/image/home_header.png')
    }
  }
}
</script>
<style scoped lang='scss'>
.page1 {
  height: 780px;
  background-color: #e6eeec;
  .container {
    width: 100%;
    height: 100%;
    padding: 20px 160px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .leftbox {
      width: 750px;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      .line {
        height: 2px;
        width: 350px;
        margin-top: 70px;
        margin-bottom: 17px;
        background-color: #333333;
      }
      .title {
        display: block;
        font-size: 60px;
        font-weight: 400;
        font-family: STHeitiSC-Medium;
        line-height: 72px;
        color: #001312;
        margin-bottom: 30px;
      }
      .tip {
        display: block;
        height: 168px;
        overflow: hidden;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 32px;
        font-family: DINNextLTPro-Regular;
        line-height: 40px;
        color: #515151;
      }
      .button {
        min-width: 250px;
        max-width: 250px;
        height: 86px;
        border: none;
        font-size: 26px;
        color: #ffffff;
        background-color: #72bd2d;
        margin-top: 90px;
      }
    }
  }
  .rightbox {
    width: 850px;
    height: 680px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
    }
  }
}
</style>