<template>
  <div class="footer">
    <div class="container">
      <div class="linkbox">
        <div class="item" v-for="(item, index) in pathList" :key="index">
          <span class="title">{{ item.title }}</span>
          <router-link class="path" v-for="(v, k) in item.list" :to="v.path" :key="k">{{ v.title }}</router-link>
        </div>
      </div>
      <div class="sendbox">
        <span class="title">BE THE FIRST TO KNOW</span>
        <span class="ltitle">Sign up for Our Newsletter</span>
        <div class="send">
          <input v-model="email" class="input" placeholder="Email Address" />
          <button @click="sendEmail" class="button">SEND</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui'
import axios from '@/axios'
export default {
  name: 'Footer',
  data() {
    return {
      pathList: [
        {
          title: 'Testimonials',
          list: [
            { title: 'Water Coating Test Report', path: '/' },
            { title: 'Henkel Glue Test Report', path: '/' },
            // { title: 'FSC-COC', path: '/' },
            { title: 'MSDS', path: '/' }
          ]
        },
        {
          title: 'Products',
          list: [
            { title: 'Mouding', path: '/Products?type=2' },
            { title: 'Shutters', path: '/Products?type=1' }
          ]
        },
        {
          title: 'Manufacturing',
          list: [{ title: 'Production Line', path: '/Manufacturing' }]
        },
        {
          title: 'Contact Us',
          list: [
            { title: 'Contact Us', path: '/contact' },
            { title: 'FAQS', path: '/contact' },
            { title: 'Information', path: '/contact' }
          ]
        },
        {
          title: 'About Us'
        }
      ],
      email: ''
    }
  },
  methods: {
    async sendEmail() {
      await axios.post(`/web/email/sendEmails?emailAddress=${this.email}`)
      Message.success('订阅成功')
    }
  }
}
</script>
<style scoped lang="scss">
.footer {
  color: #ffffff;
  background: rgb(44, 67, 23);
  height: 334px;
  padding: 50px 280px;
  .container {
    margin: auto;
    display: flex;
    .linkbox {
      flex: 1;
      display: flex;
      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        .title {
          color: #ffffff;
          font-size: 19px;
          font-weight: bold;
          line-height: 50px;
        }
        .path {
          font-size: 17px;
          margin: 5px 0;
          line-height: 30px;
          color: #f2f2f2;
          text-decoration: none;
        }
      }
    }
    .sendbox {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 26px;
        font-weight: 500;
      }
      .ltitle {
        color: #f2f2f2;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .send {
        display: flex;
        .input {
          padding: 0 15px;
          height: 36px;
          border: none;
          flex: 1;
        }
        .button {
          border: none;
          height: 36px;
          padding: 0 15px;
          color: #ffffff;
          background-color: #68a72d;
        }
      }
    }
  }
}
</style>
