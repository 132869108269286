<template>
  <div class="about">
    <div class="banner">
      <img class="img" src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_7a222e5609644490aaf91e28bad47365.png" @error="e => { e.target.src =  manufacturingImg }" alt="manufacturing.png" title="manufacturing.png" />
      <div class="back">
        <span class="title">BE THE BEST SUPPLIER</span>
        <!-- <span class="ltitle">做最好的供应商</span> -->
      </div>
    </div>
    <div class="detail">
      <div class="titlebox">
        <span class="title">ABOUT US</span>
        <div class="line"></div>
      </div>
      <div class="imgbox">
        <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_636e4a49f6a8494fbfc0c11ee92b60cd.png" @error="e => { e.target.src =  about2Img }" alt="about2.png" title="about2.png" />
        <p>Cao County Hengda Wood Products Co.,Ltd was founded in 1988 .
          The company has its own factory with 30 years experiences and covers an area of more than 165,000 square meters with 1000 employees.
          Hengda has grown to be one of the leading manufacturer and exporter of wood Mouldings & shutter components in China.
          Mainly make wooden building materials: Baseboard, wall panels, S3S and S4S boards, door and window frame or casing,handrails,
          crown mouldings ,shutters and shutter components ect. With premium quality, innovation, and sustainability our mouldings and shutters got welcomed in Europe,
          USA, Australia and many other areas.</p>
      </div>
      <div class="company-time">
        <h3 class="title">
          <img src="@/assets/image/arrow-left-title.png">
          <span>Development Path</span>
          <img src="@/assets/image/arrow-right-title.png" alt="">
        </h3>
        <div class="time-line">
          <img class="gray" src="@/assets/image/arrow-gray.png">
          <div class="img-div-box" v-for="(item, index) of timeData" :key="index">
            <img class="blue" v-if="index % 2 === 0" src="@/assets/image/arrow-dark-blue.png">
            <img class="blue" v-else src="@/assets/image/arrow-blue.png">
            <div class="white-div">
              <img class="white" src="@/assets/image/arrow-white-bg.png">
              <div class="content-div">
                <span class="point">·</span>
                <label class="line"></label>
                <div class="user"> <img src="@/assets/image/company-user.png"></div>
              </div>
              <div class="time-intro">
                <h3 v-html="item.name"></h3>
                <p v-html="item.content"></p>
              </div>
            </div>
          </div>
          <!-- <img class="blue" v-for="(item, index) of timeData" :key="index" :src="index % 2 === 0 ? darkblueImg : blueImg"> -->
          <img class="gray" src="@/assets/image/arrow-gray.png">
        </div>
      </div>
      <div class="company-time">
        <h3 class="title">
          <img src="@/assets/image/arrow-left-title.png">
          <span>Our Advantage</span>
          <img src="@/assets/image/arrow-right-title.png" alt="">
        </h3>
        <div class="ad-list">
          <div class="each-ad dark">
            <!-- <div class="dark-blue-block"></div> -->
            <p>High and stable Quality</p>
            <div class="ad-img dark-blue"><img src="@/assets/image/ad-website.png"></div>
          </div>
          <div class="each-ad dark">
            <!-- <div class="dark-blue-block"></div> -->
            <p>Short Delivery time</p>
            <div class="ad-img dark-blue"><img src="@/assets/image/ad-card.png"></div>
          </div>
          <div class="each-ad">
            <!-- <div class="blue-block"></div> -->
            <p>Competitive pricing</p>
            <div class="ad-img blue"><img src="@/assets/image/ad-email.png"></div>
          </div>
          <div class="each-ad">
            <!-- <div class="blue-block"></div> -->
            <p>Professional groups</p>
            <div class="ad-img blue"><img src="@/assets/image/ad-setting.png"></div>
          </div>
          <div class="each-ad dark">
            <!-- <div class="dark-blue-block"></div> -->
            <p>Best after-sale sevice</p>
            <div class="ad-img dark-blue"><img src="@/assets/image/ad-search.png"></div>
          </div>
          <div class="each-ad dark">
            <!-- <div class="dark-blue-block"></div> -->
            <p>Creative spirit</p>
            <div class="ad-img dark-blue"><img src="@/assets/image/ad-pc.png"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'About',
  data() {
    return {
      manufacturingImg: require('@/assets/image/manufacturing.png'),
      about2Img: require('@/assets/image/about2.png'),
      darkblueImg: require('@/assets/image/arrow-dark-blue.png'),
      blueImg: require('@/assets/image/arrow-blue.png'),
      timeData: [
        {
          time: 1988,
          name: 'Established,Raw <br /> timber boards,No <br />primer',
          content: 'Mainly to Japan Market, <br />less machine and more <br />labor periold'
        },
        { time: 1998, name: 'Trim Boards with <br />primer', content: 'Enlarge oprating plant <br />and Mainly to North <br />America' },
        { time: 2005, name: 'Start to do <br />Gesso products', content: '2ed plant finished, <br />Mordern work shop' },
        { time: 2018, name: '', content: 'Stable growing, <br />productions capacity <br />grow 3 times in 5 years <br /> <br />' },
        { time: 2021, name: '3rd plant ready', content: 'Production capacity is <br />doulbe,can work 80 <br />containers per month' },
        {
          time: 2022,
          name: '4th plant is building',
          content: 'Key products: <br />Trimb boards,Primer wood <br />moulding,work 100 <br />container per month'
        },
        { time: 2024, name: '5th plant will be <br />finished', content: 'improve service level <br />and provide quality <br />products' }
        // { time: 1988, name: 'Established,Raw timber boards,No primer', content: 'Mainly to Japan Market, less machine and more labor periold' },
        // { time: 1998, name: 'Trim Boards with primer', content: 'Enlarge oprating plant and Mainly to North America' },
        // { time: 2005, name: 'Start to do Gesso products', content: '2ed plant finished, Mordern work shop' },
        // { time: 2018, name: '', content: 'Stable growing, productions capacity grow 3 times in 5 years' },
        // { time: 2021, name: '3rd plant ready', content: 'Production capacity is doulbe,can work 80 containers per month' },
        // { time: 2022, name: '4th plant is building', content: 'Key products:Trimb boards,Primer wood moulding,work 100 container per month' },
        // { time: 2024, name: '5th plant will be finished', content: 'improve service level and provide quality products' }
      ]
    }
  }
}
</script>
<style scoped lang='scss'>
.about {
  .banner {
    height: 530px;
    position: relative;
    .img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
    }
    .back {
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;
      z-index: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      .title {
        font-size: 66px;
      }
      .ltitle {
        font-size: 46px;
        margin-top: 30px;
      }
    }
  }
  .detail {
    max-width: 1600px;
    min-width: 1200px;
    width: 90%;
    // height: 950px;
    margin: auto;
    position: relative;
    margin-top: 20px;
    .titlebox {
      width: 605px;
      background-color: #68a72d;
      margin: auto;
      position: relative;
      .title {
        display: block;
        padding-left: 66px;
        font-weight: 400;
        color: #ffffff;
        font-size: 70px;
        font-family: DINNextLTPro-Medium;
        line-height: 143px;
      }
      .line {
        width: 146px;
        height: 2px;
        background-color: #ffffff;
        position: absolute;
        bottom: 25px;
        left: 66px;
      }
    }
    .imgbox {
      display: flex;
      align-items: flex-start;
      margin-top: 30px;
      img {
        width: 48%;
        height: auto;
        margin-right: 22px;
        margin-top: 30px;
      }
      p {
        font-size: 28px;
        font-family: DINNextLTPro-Regular;
        line-height: 49px;
        color: #2e2e2e;
      }
    }
  }
  .introduce {
    height: 458px;
    position: relative;
    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 0;
      top: 0;
    }
    .textbox {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 1;
      font-size: 32px;
      color: #f4f4f4;
      line-height: 49px;
      display: flex;
      align-items: center;
      justify-content: center;
      .text {
        padding: 5% 16%;
        span {
          color: #19a3c8;
        }
      }
    }
  }
  .certificates {
    padding: 120px 0;
    text-align: center;
    .title {
      font-size: 66px;
      font-weight: bold;
    }
    .ltitle {
      font-size: 38;
      // color: #aaaaaa;
    }
    .listbox {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1600px;
      min-width: 1200px;
      margin: auto;
      img {
        width: 23%;
        margin: 10px;
        margin-top: 100px;
      }
    }
  }
  .company-time {
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px auto;
      span {
        color: #01579b;
        font-weight: 400;
        font-size: 70px;
        font-family: DINNextLTPro-Medium;
        margin: 0 20px;
      }
    }
  }
  .time-line {
    max-width: 1600px;
    min-width: 1200px;
    width: 100%;
    margin: 460px auto 420px;
    display: flex;
    align-items: center;
    justify-content: center;
    .gray {
      height: 52px;
    }
  }
}
.img-div-box {
  display: inline-flex;
  position: relative;
  &:nth-child(odd) {
    .white-div {
      bottom: 20px;
      left: -30px;
      .white {
        bottom: -8px;
      }
      .content-div {
        flex-direction: column-reverse;
        .point {
          background-color: #01569b;
        }
        .line {
          background-color: #01569b;
        }
        .user {
          border: 1px solid #01569b;
        }
      }
      .time-intro {
        // top: -140px;
        bottom: 260px;
      }
    }
  }
  &:nth-child(even) {
    .white-div {
      top: 20px;
      left: -30px;
      .white {
        top: -8px;
      }
      .content-div {
        flex-direction: column;
        .point {
          background-color: #29b6f6;
        }
        .line {
          background-color: #29b6f6;
        }
        .user {
          border: 1px solid #29b6f6;
        }
      }
    }
  }
  .white {
    display: inline-block;
    width: 28px;
    height: 28px;
    position: absolute;
    left: 50%;
    margin-left: -14px;
    z-index: -1;
  }
  .blue {
    height: 52px;
    flex-grow: 1;
  }
  .white-div {
    width: 240px;
    // width: 132px;
    height: 252px;
    position: absolute;
    z-index: 2;
    .content-div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      z-index: 3;
      .point {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 12px;
      }
      .line {
        display: inline-block;
        width: 2px;
        height: 90px;
      }
      .user {
        width: 150px;
        height: 150px;
        border-radius: 150px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 150px;
        }
      }
    }
    .time-intro {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      h3 {
        // height: max-content;
        // height: 90px;
        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        text-align: center;
      }
      p {
        // height: max-content;
        // height: 130px;
        white-space: nowrap;
        text-overflow: clip;
        overflow: hidden;
        text-align: center;
        color: rgb(121, 119, 119);
        line-height: 32px;
      }
    }
  }
}
.ad-list {
  width: 1366px;
  margin: 0 auto 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  .each-ad {
    width: 49%;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgb(210, 209, 209);
    border-radius: 0 48px 48px 0;
    margin-bottom: 20px;
    p {
      font-weight: lighter;
      font-size: 20px;
    }
    .dark-blue-block {
      background-color: #01569b;
    }
    .blue-block {
      background-color: #29b6f6;
    }
    .ad-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border-radius: 60px;
      &.dark-blue {
        background-color: #01569b;
      }
      &.blue {
        background-color: #29b6f6;
      }
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>
