<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->

    <Header />
    <div id="container">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  watch: {
    $route(to, from) {
      if (to.query.type && to.query.type != from.query.type) {
      } else {
        document.scrollingElement.scrollTop = 0
      }
    }
  }
}
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  word-break: break-word;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 16px;
  min-height: 100vh;
  width: 100%;
  font-weight: 400;
}
#container {
  padding-top: 120px;
}
input {
  outline: none;
  :focus {
    outline: none;
  }
}
</style>
