<template>
  <div class="pruducts">
    <div class="banner">
      <img class="img" src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_d66994f8b14f409da7022bb484474dcf.png" @error="e => { e.target.src =  productBannerImg}" alt="product-banner.png" title="product-banner.png" />
      <div class="back">
        <span class="title">HIGH QUALITY, GOOD REPUTATION</span>
      </div>
    </div>
    <template v-if="products && products[0]">
      <div v-for="(item, index) in products[0].webProductVoList" :key="index">
        <div class="goodsbox" :id="item.id">
          <div class="title">{{ item.productName }}</div>
          <div class="listbox">
            <div class="listitem" v-for="(row, key) in item.productImageList" :key="key">
              <img :src="row.productImageUrl" />
              <span>{{ row.productImageName }}</span>
            </div>
          </div>
        </div>
        <div class="imgbox" v-if="index == 1">
          <div>
            <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_f4ec47995c664f6abeec329dcdfcb769.png" @error="e => { e.target.src =  product5Img}" alt="product5.png" title="product5.png" />
          </div>
          <div>
            <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_390576094b4f4bb0add713e6de1ae23a.png" @error="e => { e.target.src =  product6Img}" alt="product6.png" title="product6.png" />
            <span>.Base moulding protects walls from kicking,bumping and decorates walls</span>
            <span>.Crowns cover angles and customized products are available</span>
            <span>.MDF and Plywood mouldings are durable and strong</span>
            <span>.An extensive of plantation shutters help people choose the
              right window furnishing</span>
          </div>
          <div>
            <img src="https://yzf.qq.com/fsna/kf-file/kf_pic/20221102/KFPIC_kfh5221fa29cfc019f_h5cded9881fc7d6fdfece5fb364b_WXIMAGE_020a5266330f4a3c8e018a6d102ecfbb.png" @error="e => { e.target.src =  product7Img}" alt="product7.png" title="product7.png" />
          </div>
        </div>
      </div>

    </template>
    <div class="shutters">
      <div class="title" id="1">Shutters</div>
      <div class="ltitle">Shutter Components</div>
      <div class="listbox" v-if="products && products[1]">
        <div class="listitem" v-for="(item, index) in products[1].webProductVoList" :key="index" :id="item.id">
          <div class="imgbox1" v-for="(row, key) in item.productImageList" :key="key">
            <img :src="row.productImageUrl" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios'
export default {
  name: 'Products',
  components: {},
  data() {
    return {
      products: [],
      selectIndex: 1,
      productBannerImg: require('@/assets/image/product-banner.png'),
      product5Img: require('@/assets/image/product5.png'),
      product6Img: require('@/assets/image/product6.png'),
      product7Img: require('@/assets/image/product7.png')
    }
  },
  async created() {
    const res = await axios.post('/web/PProduct/findPProduct')
    const list = res.data
    this.products = list
  },
  watch: {
    $route(to, from) {
      debugger
      if (to.query.type && to.query.type != from.query.type) {
        console.log(to.query.type, from.query.type)
        var div = document.getElementById(to.query.type)
        console.log(div.offsetTop)
        var reactObj = div.getBoundingClientRect()
        console.log(reactObj)
        document.scrollingElement.scrollTop = div.offsetTop - 240
        // debugger
        // const offset = this.$refs[to.query.type + 'Ref'][0].getBoundingClientRect() // vue中，使用this.$el获取当前组件的根元素
        // const offsetTop = offset.top
        // debugger
        // this.$refs[to.query.type + 'Ref'][0].scrollTop = offsetTop
      }
    }
  }
}
</script>
<style scoped lang='scss'>
.pruducts {
  .banner {
    height: 530px;
    position: relative;
    .img {
      height: 100%;
      width: 100%;
      position: absolute;
      z-index: 0;
    }
    .back {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      position: relative;
      z-index: 1;
      .title {
        font-size: 66px;
      }
      .ltitle {
        font-size: 46px;
        margin-top: 30px;
      }
    }
  }
  .goodsbox {
    min-width: 1000px;
    width: 82%;
    margin: 30px auto;
    .title {
      font-size: 66px;
      font-weight: 500;
      color: #3b3b3b;
      line-height: 80px;
      margin-top: 40px;
      padding-left: 15px;
    }
    .ltitle {
      font-size: 42px;
      font-weight: 500;
      color: #838383;
      line-height: 80px;
      margin-top: 20px;
      margin-bottom: 40px;
      padding-left: 15px;
    }
    .listbox {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 20px;
      .listitem {
        width: 25%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 30px;
        img {
          width: 92%;
          height: 260px;
          display: block;
          margin-bottom: 20px;
        }
        span {
          display: block;
          min-height: 80px;
        }
      }
    }
  }
  .imgbox {
    display: flex;
    flex-direction: row;
    div {
      flex: 1;
      height: 400px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        position: absolute;
        width: 100%;
        z-index: 0;
      }
      span {
        position: relative;
        z-index: 1;
        font-size: 1.5vw;
        color: #d6d6d6;
        padding: 0 20px;
      }
    }
  }
  .shutters {
    background-color: #f9f9f9;
    width: 90%;
    padding: 5%;
    margin: auto;
    min-width: 1100px;
    // padding: 0 9%;
    .title {
      font-size: 66px;
      // margin-top: 110px;
    }
    .ltitle {
      font-size: 42px;
      color: #838383;
      margin-top: 40px;
    }
    .listbox {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 80px;
      align-items: center;
      .listitem {
        text-align: center;
        font-size: 30px;
        align-items: center;
        .imgbox1 {
          float: left;
          width: 23%;
          margin: 25px 1%;
          margin-bottom: 0;
          img {
            width: 100%;
            height: 260px;
          }
        }
      }
      .listitem:nth-child(4n + 1) {
        margin-left: 0;
      }
      .listitem:nth-child(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>